import * as React from "react";
import { Accordion } from "react-bootstrap";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import CardValores from "../../components/CardValores/CardValores";
import AccordeonConteudo from "../../components/AccordeonConteudo/AccordeonConteudo";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgCursosTecnicos from "../../assets/images/etapas-de-aprendizagem/cursos-tecnicos/cursos-tecnicos.png";
import cursosTecnicos from "../../data/etapas-de-aprendizagem/cursos-tecnicos/cursos-tecnicos.json";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function CursoTecnicos() {
	return (
		<Layout>
			<SEO title="Cursos técnicos" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgterciary-gradient">cursos técnicos</h1>
						<h3 className="fgterciary-gradient">nosso propósito</h3>
						<p className="text-justify">
							A Escola de Aplicação Feevale promove a formação de profissionais
							de nível técnico para atender às demandas sociais e econômicas da
							região.
						</p>
						<p className="text-justify">
							Com isso, buscamos oferecer aos estudantes condições de construir
							seus conhecimentos técnicos e científicos, através do
							desenvolvimento do pensamento lógico, da postura investigativa,
							criativa e crítica, para que exerçam suas cidadanias de forma
							consciente, assumindo responsabilidades sociais, éticas e
							políticas nas suas atuações no mundo do mercado de trabalho.
						</p>
						<p className="text-justify">
							Para tal, a Escola oferta seis cursos técnicos: em Administração,
							Desenvolvimento de Aplicativos, Gestão de Negócios Inovadores,
							Multimídia, Publicidade e em Informática para Internet.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgCursosTecnicos} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient">nossos cursos</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						{cursosTecnicos ? (
							<Accordion defaultActiveKey="0" className="pt-2">
								{cursosTecnicos.map((curso, index) => {
									return (
										<>
											<AccordeonConteudo title={curso.title} index={index}>
												<MDBRow className="pt-3 px-2">
													<MDBCol>
														<p
															className="text-justify"
															dangerouslySetInnerHTML={{
																__html: curso.description,
															}}
														/>
														<p className="font-weight-bold text-justify">
															{curso.tituloItens}
														</p>
														<ul style={{ listStyleType: "none" }}>
															{curso.itensCapacidades.map(
																(Capacidades, index) => (
																	<li key={index}>// {Capacidades}</li>
																)
															)}
														</ul>
														<p className="font-weight-bold">
															Componentes curriculares
														</p>
														<MDBRow>
															{curso.modulos.map((modulo, index) => (
																<MDBCol
																	key={index}
																	col={12}
																	md={6}
																	className="my-2"
																>
																	<div className="box-ensino px-3 pt-2">
																		<span className="font-weight-bold h5">
																			{modulo.title}
																		</span>
																		<ul style={{ listStyleType: "none" }}>
																			{modulo.disciplinas.map(
																				(disciplina, index) => (
																					<li key={index}>// {disciplina}</li>
																				)
																			)}
																		</ul>
																	</div>
																</MDBCol>
															))}
														</MDBRow>
													</MDBCol>
												</MDBRow>
											</AccordeonConteudo>
										</>
									);
								})}
							</Accordion>
						) : (
							<p>Em breve novos cursos técnicos.</p>
						)}
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-4">nossos cursos</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="6"
						value="758,49"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="5"
						value="910,18"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="4"
						value="1.137,73"
					/>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-4">certificação</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="text-justify">
							Receberá o Diploma de Técnico, o estudante que tiver concluído o
							Ensino Médio e habilitação em todos os módulos do curso.
							Estudantes que ainda não concluíram o Ensino Médio, receberão a
							Qualificação Profissional após habilitação em cada módulo
							concluído e Atestado de Conclusão com previsão de data da
							finalização do Ensino Médio.
						</p>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
